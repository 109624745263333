import { call, put, takeEvery } from 'redux-saga/effects';
import { get, post } from '../actions/api';
import { SUCCESS_USERS, GET_USERS_REQUEST, POST_USER_REQUEST, POST_SUCCESS, POST_FAILED } from '../types';
import axios from 'axios';

function* getUsers({ type, payload }) {
    try {
        const data = yield call(get, "user/", payload);
        if (data) {
            yield put({ "type": SUCCESS_USERS, "data": data });
            //   console.log(data);
        }

    } catch (error) {
        console.log(error);
        yield put({ "type": POST_FAILED, "data": []});
    }
}

function* saveUser({ type, payload }) {
    try {
        console.log("saveUser", payload)
        const data = yield call(post, "user/", payload);
        console.log("registeredData", data)
        yield put({ "type": POST_SUCCESS, "data": data });
    } catch (error) {
        console.log(error)
        yield put({ "type": POST_FAILED, "data": []});
    }
}

function* userSaga() {
    yield takeEvery(GET_USERS_REQUEST, getUsers);
    yield takeEvery(POST_USER_REQUEST, saveUser);
}

export default userSaga