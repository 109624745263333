import * as type from '../types';
const storeName = "company"
const initialState = {
    data: JSON.parse(localStorage.getItem(storeName)) || [],
    // data:[],
    loading: false,
    message:"",
    success:false,
};

export default function company(state = initialState, action) {
    // console.log("action",action.type)
    switch (action.type) {
        case type.GET_COMPANY_REQUEST || type.POST_COMPANY_REQUEST ||  type.PUT_CNOTE_REQUEST || type.PATCH_COMPANY_REQUEST ||
        type.DELETE_COMPANY_REQUEST:
            // console.log("data" + action.payload);
            return {
                ...state,
                loading: true,
            };
        case type.COMPANY_SUCCESS:
            let datas = [...state.data]
            if (typeof action.data === 'object') {
                if (Array.isArray(action.data)) {
                    datas = action.data
                }else{
                    datas.push(action.data)
                }
            }
            if (typeof action.data !== 'object') {
                datas = datas.filter(d => d.id !== action.data);
            }
            localStorage.setItem(storeName, JSON.stringify(datas))
            return {
                ...state,
                data: action.data,
                loading: false,
                success:true,
                // message:"Data Successfully Saved"
            
            };
        case type.COMPANY_FAILED:
            return {
                ...state,
                loading: false,
                message:"Failed This Action ",
                success:false
            };
        default:
            return state;
    }

}