import * as type from '../types';
const storeName = "dashboard"
const initialState = {
    data: JSON.parse(localStorage.getItem(storeName)) || [],
    loading: false,
};

export default function dashboard(state = initialState, action) {
    switch (action.type) {
        case type.GET_DASHBOARD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case type.DASHBOARD_SUCCESS:
            let datas = [...state.data]
            if (typeof action.data === 'object') {
                if (Array.isArray(action.data)) {
                    datas = action.data
                }else{
                    datas.push(action.data)
                }
            }
            if (typeof action.data !== 'object') {
                datas = datas.filter(d => d.id !== action.data);
            }
            localStorage.setItem(storeName, JSON.stringify(datas))
            return {
                ...state,
                data: action.data,
                loading: false
            };
        case type.DASHBOARD_FAILED:
            return {
                ...state,
                data: [],
                loading: false
            };
        default:
            return state;
    }

}