import * as type from '../types';
const storeName = "group"
// localStorage.setItem(storeName , JSON.stringify([]))
const initialState = {
    data: JSON.parse(localStorage.getItem(storeName)) || [],
    loading: false,
    message:"",
    success:false,
};

export default function group(state = initialState, action) {
    // console.log("action",action.type)
    switch (action.type) {
        case type.GET_GROUP_REQUEST || type.POST_GROUP_REQUEST || type.PUT_GROUP_REQUEST||type.PATCH_GROUP_REQUEST ||
        type.DELETE_GROUP_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case type.GROUP_SUCCESS:
            let datas = [...state.data]
            if (typeof action.data === 'object') {
                if (Array.isArray(action.data)) {
                    datas = action.data
                }else{
                    datas.push(action.data)
                }
            }
            if (typeof action.data !== 'object') {
                datas = datas.filter(d => d.id !== action.data);
            }
            localStorage.setItem(storeName, JSON.stringify(datas))
            return {
                ...state,
                data:datas,
                loading: false,
                success:true,
                message:"Data Successfully Saved"
                
            };
            
        case type.GROUP_FAILED:
            return {
                ...state,
                loading: false,
                message:"Failed This Action ",
                success:false
            };
        default:
            return state;
    }

}