import {call, put, takeEvery,takeLatest} from 'redux-saga/effects';
import {get, post} from '../actions/api';
import {CHART_SUCCESS,CHART_FAILED, GET_CHART_REQUEST} from '../types';

function* getData({type, payload}) {
    try {
        const data = yield  call(get, "chart/0/authentic_user/date_joined/helo/");
        // console.log("from api:",data)
        if (data) {
            yield put({"type": CHART_SUCCESS, "datas": data});
        }

    }catch(error){
        yield put({"type":CHART_FAILED,"datas": {}});
    }
}
function* chartSaga(){
    yield takeLatest(GET_CHART_REQUEST, getData);
}
export default chartSaga