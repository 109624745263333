import { call, put, takeEvery } from 'redux-saga/effects';
import { get, post, patch, del } from '../actions/api';
import * as action from '../types';
import { isNumber } from '../../components/date/dates';

function* _search({ type, payload, callback }) {
    try {
        console.log(payload)
        const data = yield call(get, payload);
        if (data && data.length > 0) {
            if (callback) callback(data)
        } else {
            if (callback) callback([])
        }
    } catch (error) {
        if (callback) callback([])
    }
}
function* _get({ type, payload }) {
    let url = payload;
    if (isNumber(payload)) {
        payload = payload ? payload : 0;
       url =  `group/${payload}/`
    }
    console.log("request called",url)
    try {
        const data = yield call(get, url);
        if (data.error) {
            yield put({ "type": action.GROUP_FAILED, "data": data });
        } else if (data) {
            console.log("fecth total data - ", data.length)
            yield put({ "type": action.GROUP_SUCCESS, "data": data });
            yield put({ "type": action.GROUP_FAILED, "data": { error: false, message: "" } });
        } else {
            yield put({ "type": action.GROUP_FAILED, "data": { error: true, message: "Unknown Error" } });
        }
    } catch (error) {
        yield put({ "type": action.GROUP_FAILED, "data": { error: true, message: "Unknown Error" } });
    }

}

function* _post({ type, payload, callback }) {
    try {
        console.log("saveNote", payload)
        const data = yield call(post, "group/", payload);
        if (data.error) {
            console.log("error1:", JSON.stringify(data));
            yield put({ "type": action.GROUP_FAILED, "data": data });
        } else if (data) {
            console.log("success:", JSON.stringify(data));
            console.log("fecth total data - ", data.length)
            yield put({ "type": action.GROUP_SUCCESS, "data": data });
            yield put({ "type": action.GROUP_FAILED, "data": { error: false, message: "Data successfully added" } });
        } else {
            console.log("error2:", JSON.stringify(data));
            yield put({ "type": action.GROUP_FAILED, "data": { error: true, message: "Unknown Error" } });
        }
        if (callback) callback(data)
    } catch (error) {
        console.log("error3:");
        yield put({ "type": action.GROUP_FAILED, "data": { error: true, message: "Unknown Error" } });
        if (callback) callback({ error: true, message: "Unknown Error" })
    }

}
function* _put({ type, payload, callback }) {
    try {
        console.log("editNote", payload)
        const data = yield call(put, `group/${payload.id}/`, payload);
        if (data.error) {
            yield put({ "type": action.GROUP_FAILED, "data": data });
        } else if (data) {
            console.log("fecth total data - ", data.length)
            yield put({ "type": action.GROUP_SUCCESS, "data": data });
            yield put({ "type": action.GROUP_FAILED, "data": { error: false, message: "" } });
        } else {
            yield put({ "type": action.GROUP_FAILED, "data": { error: true, message: "Unknown Error" } });
        }
        if (callback) callback({ error: true, message: "Unknown Error" })
    } catch (error) {
        yield put({ "type": action.GROUP_FAILED, "data": { error: true, message: "Unknown Error" } });
        if (callback) callback({ error: true, message: "Unknown Error" })
    }
}
function* _patch({ type, payload, callback }) {
    try {
        console.log("editNote", payload)
        const data = yield call(patch, `group/${payload.id}/`, payload);    
        if (data.error) {
            yield put({ "type": action.GROUP_FAILED, "data": data });
        } else if (data) {
            console.log("fecth total data - ", data.length)
            yield put({ "type": action.GROUP_SUCCESS, "data": data });
            yield put({ "type": action.GROUP_FAILED, "data": { error: false, message: "" } });
        } else {
            yield put({ "type": action.CNOR_FAILED, "data": { error: true, message: "Unknown Error" } });
        }
        if (callback) callback(data)
    } catch (error) {
        yield put({ "type": action.GROUP_FAILED, "data": { error: true, message: "Unknown Error" } });
        if (callback) callback({ error: true, message: "Unknown Error" })
    }

}
function* _del({ type, payload, callback }) {
    try {
        console.log("delete", payload)
        const data = yield call(del, "group/", payload);
        if (data.error) {
            yield put({ "type": action.GROUP_FAILED, "data": data });
        } else if (data) {
            console.log("fecth total data - ", data.length)
            yield put({ "type": action.GROUP_SUCCESS, "data": data });
            yield put({ "type": action.GROUP_FAILED, "data": { error: false, message: "" } });
        } else {
            yield put({ "type": action.GROUP_FAILED, "data": { error: true, message: "Unknown Error" } });
        }
        if (callback) callback(data)
    } catch (error) {
        yield put({ "type": action.GROUP_FAILED, "data": { error: true, message: "Unknown Error" } });
        if (callback) callback({ error: true, message: "Unknown Error" })
    }

}
function* groupSaga() {
    yield takeEvery(action.GET_GROUP_REQUEST, _get);
    yield takeEvery(action.POST_GROUP_REQUEST, _post);
    yield takeEvery(action.PUT_GROUP_REQUEST, _put);
    yield takeEvery(action.PATCH_GROUP_REQUEST, _patch);
    yield takeEvery(action.DELETE_GROUP_REQUEST, _del);
    yield takeEvery(action.SEARCH_GROUP_REQUEST, _search);
}

export default groupSaga