import {Form} from "react-bootstrap";
import React from "react";
import './float-inputs.css'

const TextBox = (props,ref) => {
    const [error, setError] = React.useState('')
    let name = props.name || ""
    let label = props.label || name
    let placeholder = props.placeholder || label ? "Enter " + label : ""
    let message = props.message || placeholder
    let dv = props.defaultValue || ""
    let req = props.required === false ? {} : {"required": true}
    let size = props.size || "sm"
    let value = props.value 


    const onlyAlphabets = (e) => {
        if (props.onInput) {
            props.onInput(e);
        }
        var regex = /^[a-zA-Z]*$/;
        if (regex.test(e.target.value)) {
            return true;
        } else {
            setError("Alphabets Only");
            return false;
        }
    }

    return (
        <Form.Group >
            <div className={"float-input"}>
                <Form.Control
                    {...req}
                    type="text"
                    placeholder={" "}
                    {...props}
                    onInput={(e) => {
                        onlyAlphabets(e)
                    }}
                    id={name}
                    value={value}
                    ref={ref}
                />
                {props.type!=="hidden" &&<label htmlFor={name}>{label}</label>}
                {error&&<Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
            </div>


        </Form.Group>
    )
}
export default React.forwardRef(TextBox)