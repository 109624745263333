import React from 'react';
import "./DetailsCard.css";
import {Card, Container, Row, Col} from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons';

function DetailsCard(props) {
    let icons = {location: <Icon.Pin className={"icon"}/>,'user':<Icon.People className={"icon"}/>}

    return (
        <Container fluid>
            <Row>
                {Array.isArray(props.data) && props.data?.map((data, i) => {
                    return (
                        <Col md={3} lg key={i}>
                            <Card className='p-3 tile-icons'>
                                {icons[data.name]}
                                {!icons[data.name] && <Icon.Bookmark className={"icon"}/>}
                                <div>
                                    <div className='num pt-4'>{data.count}</div>
                                    <div className={"pt-1"} style={{textTransform: "capitalize"}}>{data.name}</div>
                                </div>
                            </Card>
                        </Col>
                    )
                })}
                {/*<Col md={3} lg>*/}
                {/*    <Card className='p-3'>*/}
                {/*        <Icon.Camera className='text-info2'/>*/}
                {/*        <p className='num2'>5</p>*/}
                {/*        <p>Timelapse camera</p>*/}

                {/*    </Card>*/}
                {/*</Col>*/}
                {/*<Col md={3}>*/}
                {/*    <Card className='p-3'>*/}
                {/*        <Icon.People className='text-info3'/>*/}
                {/*        <p className='num3'>356</p>*/}
                {/*        <p>Users</p>*/}

                {/*    </Card>*/}
                {/*</Col>*/}
                {/*<Col md={3}>*/}
                {/*    <Card className='p-3'>*/}
                {/*        <Icon.Chat className='text-info4'/>*/}
                {/*        <p className='num4'>127</p>*/}
                {/*        <p>Enquiries</p>*/}

                {/*    </Card>*/}
                {/*</Col>*/}
            </Row>
        </Container>
    )
}

export default DetailsCard