import { combineReducers } from 'redux';
import users from './users';
import auth from './login';
import dashboard from './dashboard';
import chart from './charts';
import cnotes from './cnote';
import cnor from './consignor';
import place from './place';
import company from './company'
import transport from './transport'
import invoice from './invoice'
import group from './group'
const rootReducer = combineReducers({
    auth: auth,
    users: users,
    dashboard: dashboard,
    chart: chart,
    cnotes: cnotes,
    cnor: cnor,
    place: place,
    company: company,
    transport: transport,
    invoice:invoice,
    group:group,
});
export default rootReducer;