import {call, put, takeEvery, takeLatest} from 'redux-saga/effects';
import {get, post} from '../actions/api';
import {DASHBOARD_SUCCESS,DASHBOARD_FAILED, GET_DASHBOARD_REQUEST} from '../types';

function* getDashboard({type, payload}) {
    try {
        const data = yield  call(get, "dashboard/0/");
        console.log("from api:",data)
        if(data) {
            yield put({"type": DASHBOARD_SUCCESS, "data": data});
        }

    }catch(error){
        console.log(error)
    }
}
function* dashBoardSaga(){
    yield takeLatest(GET_DASHBOARD_REQUEST, getDashboard);
}
export default dashBoardSaga