import * as type from '../types';
const storeName = "cnote"
localStorage.setItem(storeName, JSON.stringify([]))
const initialState = {
    data: JSON.parse(localStorage.getItem(storeName)) || [],
    loading: false,
    message: "",
    success: false,
};

export default function cnotes(state = initialState, action) {
    switch (action.type) {
        case type.GET_CNOTE_REQUEST || type.POST_CNOTE_REQUEST || type.PUT_CNOTE_REQUEST || type.PATCH_CNOTE_REQUEST ||
            type.DELETE_CNOTE_REQUEST:

            return {
                ...state,
                loading: true,
            };
        case type.CNOTE_SUCCESS:
            let datas = [...state.data]
            if (typeof action.data === 'object') {
                if (Array.isArray(action.data)) {
                    datas = action.data
                }else{
                    datas.push(action.data)
                }
            }
            if (typeof action.data !== 'object') {
                datas = datas.filter(d => d.id !== action.data);
            }
            localStorage.setItem(storeName, JSON.stringify(datas))
            return {
                ...state,
                data: [...datas],
                loading: false,
                message: "Data successfully updated",
                success: true
            };
        case type.CNOTE_FAILED:
            console.log("failed section")
            return {
                ...state,
                loading: false,
                message: action.data.message,
                success: !action.data.error,
            };
        default:
            return state;
    }

}