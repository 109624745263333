import * as type from '../types';
const storeName = "invoice"
const initialState = {
    data: JSON.parse(localStorage.getItem(storeName)) || [],
    loading: false,
    message:"",
    success:false,
};

export default function invoice(state = initialState, action) {
    // console.log("action",action.type)
    switch (action.type) {
        case type.GET_INVOICE_REQUEST || type.POST_INVOICE_REQUEST || type.PUT_INVOICE_REQUEST||type.PATCH_COMPANY_REQUEST ||
        type.DELETE_INVOICE_REQUEST:
            console.log("data" + action.payload);
            return {
                ...state,
                loading: true,
            };
        case type.INVOICE_SUCCESS:
            let datas = [...state.data]
            if (typeof action.data === 'object') {
                if (Array.isArray(action.data)) {
                    datas = action.data
                }else{
                    datas.push(action.data)
                }
            }
            if (typeof action.data !== 'object') {
                datas = datas.filter(d => d.id !== action.data);
            }
            localStorage.setItem(storeName, JSON.stringify(datas))
            return {
                ...state,
                data: action.data,
                loading: false,
                success:true
            };
        case type.INVOICE_FAILED:
            return {
                ...state,
                data: [],
                loading: false,
                message:"Failed This Action ",
                success:false
            };
        default:
            return state;
    }

}