import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import logo from "../assets/logo/webscript.png";
import user from "../assets/user.jpg";
import * as Icon from 'react-bootstrap-icons';
import MenuItem from "./MenuItem";
import './sidemenu.css'

import Message from "../ui/whatapp/Message";
import Dashboard from "../ui/dashboard/Dashboard";
import Company from "../ui/company/Company";

import User from "../ui/user/User";
import Report from "../ui/report/Report";
import Cnote from "../ui/cnote/Cnote";
import Place from '../ui/place/Place';
import Transport from '../ui/transport/Transport';
import Consignor from "../ui/consignor/Consignor"
import Invoice from "../ui/invoice/Invoice"
import Bills from "../ui/bills/Bills";
import Editor from "../ui/swap/Editor";
import FileUpload  from "../components/fileupload/FileUpload";
import Group from "../ui/auth/Group";

export const menuItems = [
    {
        name: "Dashboard",
        exact: true,
        to: "/dashboard",
        icon: <Icon.Microsoft />,
        component: <Dashboard />
    },
    // {
    //     name: "Admin",
    //     to: `/admin`,
    //     icon: <Icon.PersonFillGear/>,
    //     subMenus: [
    //         { name: "Group", to: "/admin/group", icon: <Icon.Grid/>, component:<Group/> },
    //         { name: "Permission", to: "/admin/permission" ,component:<Group/> },
    //     ],
    // },
    // {
    //     name: "Entry",
    //     exact: true,
    //     to: `/cnote`,
    //     icon: <Icon.Clipboard />,
    //     component: <Cnote />
    // },
    // {
    //     name: "Billing",
    //     exact: true,
    //     to: `/bills`,
    //     icon: <Icon.CashCoin />,
    //     component: <Bills />
    // },
    // {
    //     name: "Company",
    //     exact: true,
    //     to: `/company`,
    //     icon: <Icon.Building />,
    //     component: <Company />,
    //     subMenus: [
    //         { name: "Courses", to: "/content/courses", icon: <Icon.Activity /> },
    //         { name: "Videos", to: "/content/videos" },
    //     ],
    // },
    // {
    //     name: "Place",
    //     exact: true,
    //     to: `/place`,
    //     icon: <Icon.GeoAlt />,
    //     component: <Place />,
    // },
    // {
    //     name: "Transport",
    //     exact: true,
    //     to: `/transport`,
    //     icon: <Icon.Airplane />,
    //     component: <Transport />,
    // },
    // {
    //     name: "Consignor",
    //     exact: true,
    //     to: `/consignor`,
    //     icon: <Icon.PersonVcard />,
    //     component: <Consignor />,
    // },
    // {
    //     name: "Users",
    //     exact: true,
    //     to: `/user`,
    //     icon: <Icon.Person />,
    //     component: <User />,
    // },
    // { name: "Print", to: `/print`, icon: <Icon.Printer />,component: <Invoice/>},
    { name: "Message", to: `/message`, icon: <Icon.Whatsapp />,component: <Message/>},
    // { name: "FileUpload", to: `/upload`, icon: <Icon.File />,component: <FileUpload/>},
    // {name: "Project", to: `/design`, icon: <Icon.WindowStack/>},
    // {
    //     name: "Partners",
    //     exact: true,
    //     to: `/content-2`,
    //     icon: <Icon.People/>,
    //     subMenus: [
    //         {name: "Courses", to: "/content-2/courses"},
    //         {name: "Videos", to: "/content-2/videos"},
    //     ],
    // },

    // {name: "Camera", to: `/camera`, icon: <Icon.Camera/>},
    // {name: "Live Camera", to: `/design-3`, icon: <Icon.CameraVideo/>},

];

const SideMenu = (props) => {
    const [inactive, setInactive] = useState(false);

    useEffect(() => {
        removeActiveClassFromSubMenu();

        // props.onCollapse(inactive);
    }, []);
    useEffect(() => {
        document.addEventListener('mouseup', function (e) {
            var container = document.getElementById('side-menu');
            var toggle = document.getElementById('sidebarToggle');
            if (container && !container.contains(e.target) && !toggle.contains(e.target)) {
                container.classList.add('d-none')
            }
        });
    }, [])

    //just an improvment and it is not recorded in video :(
    const removeActiveClassFromSubMenu = () => {
        document.querySelectorAll(".sub-menu").forEach((el) => {
            el.classList.remove("active");
        });
    };

    /*just a little improvement over click function of menuItem
      Now no need to use expand state variable in MenuItem component
    */
    useEffect(() => {
        let menuItems = document.querySelectorAll(".menu-item");
        menuItems.forEach((el) => {
            // console.log(el.pathname ,"menu path", window.location.pathname)

            el.addEventListener("click", (e) => {
                const next = el.nextElementSibling;
                removeActiveClassFromSubMenu();
                menuItems.forEach((el) => el.classList.remove("active"));
                el.classList.toggle("active");
                // console.log(next);
                if (next !== null) {
                    next.classList.toggle("active");
                }
            });
            if (el.pathname == window.location.pathname) {
                const next = el.nextElementSibling;
                removeActiveClassFromSubMenu();
                menuItems.forEach((el) => el.classList.remove("active"));
                el.classList.toggle("active");
                // console.log(next);
                if (next !== null) {
                    next.classList.toggle("active");
                }
            }
        });
    }, []);

    return (
        <div className="d-none d-md-block bg-light side-menu" id={'side-menu'}>
            <div className="top-section text-center">
                <div className="logo">
                    <img src={logo} alt="webscript" />
                </div>
            </div>

            {/*<div className="search-controller">*/}
            {/*    <button className="search-btn">*/}
            {/*        <i className="bi bi-search"></i>*/}
            {/*    </button>*/}

            {/*    <input type="text" placeholder="search"/>*/}
            {/*</div>*/}

            <div className="divider mt-3"></div>

            <div className="main-menu">
                <ul>
                    {menuItems.map((menuItem, index) => (
                        <MenuItem
                            key={uuidv4()}
                            name={menuItem.name}
                            exact={"true"}
                            to={menuItem.to}
                            subMenus={menuItem.subMenus || []}
                            icon={menuItem.icon}
                            onClick={(e) => {
                                if (inactive) {
                                    //setInactive(false);
                                }
                            }}
                        />
                    ))}
                </ul>
            </div>

            {/*<div className="side-menu-footer">*/}
            {/*    <div className="avatar">*/}
            {/*        <img src={user} alt="user"/>*/}
            {/*    </div>*/}
            {/*    <div className="user-info">*/}
            {/*        <h6>Rizwan Khan</h6>*/}
            {/*        <p>rizwankhan@gmail.com</p>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
};

export default SideMenu;
