import React from 'react';
import "./company.css";
import {Card, Container, Row, Col, Image, ListGroup, Badge, Figure} from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import img404 from  '../../assets/img404.png';

function CompanyDetails(props) {
    let {data} = props;
    let isValid = (data != undefined && Object.keys(data).length > 0);
    return (
        <>
            {(isValid) &&
            <Card className='w-100 p-3' style={{"marginTop": "3.5em"}}>
                <Figure>
                <Row>
                    <Col className={"p-2 d-flex align-items-center justify-content-center"}>
                        <Figure.Image
                            width={"100%"}
                            alt="171x180"
                            src={data.img}
                            onError={(obj)=>{obj.target.src=img404;obj.target.style.width='30%'}}
                        />
                    </Col>
                    <Col className={"p-3"}>
                        <h5 style={{textTransform:"uppercase"}}>{data.name}</h5>
                        <ListGroup.Item>
                            <hr className={"mt-4"}/>
                            <h6 className={"mt-2 text-warning"}>Contact Details</h6>
                            <ListGroup horizontal className="px-4">
                                <ListGroup.Item className={"p-1"}>
                                    <Icon.PhoneFill/>
                                </ListGroup.Item>
                                <ListGroup.Item className={"p-1"}>
                                    <div>{data.phone}</div>
                                </ListGroup.Item>
                            </ListGroup>
                            <ListGroup horizontal className="px-4">
                                <ListGroup.Item className={"p-1"}>
                                    <Icon.EnvelopeFill/>
                                </ListGroup.Item>
                                <ListGroup.Item className={"p-1"}>
                                    <div>{data.email}</div>
                                </ListGroup.Item>
                            </ListGroup>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <hr className={"mt-4"}/>
                            <h6 className={"mt-2 text-warning"}>More Details</h6>
                            <p>Host your entire website on Shopify. You have complete control over the look and
                                feel. From its layout, to content and colors. Choose from templates and themes in
                                the Shopify theme store, or build it from scratch with full access to the HTML and
                                CSS of your store.</p>
                        </ListGroup.Item>
                    </Col>
                </Row>

                <Figure.Caption>
                    <hr className={"mt-4"}/>
                    <Badge pill bg="light" text="dark" className={"caption"}>
                        <Badge pill text={"light"} className={"mx-2"}>
                            12
                        </Badge>Projects
                    </Badge>
                    <Badge pill bg="light" text="dark" className={"caption"}>
                        <Badge pill text={"light"} className={"mx-2"}>
                            24
                        </Badge>Users
                    </Badge>
                    <Badge pill bg="light" text="dark" className={"caption"}>
                        <Badge pill bg={"warning"} text={"light"} className={"mx-2"}>
                            16
                        </Badge>Timelapse
                    </Badge>
                    <Badge pill bg="light" text="dark" className={"caption"}>
                        <Badge pill bg={"danger"} text={"light"} className={"mx-2"}>
                            32
                        </Badge>Live
                    </Badge>
                </Figure.Caption>
            </Figure>
            </Card>
            }

            {(!isValid) && <Card className='w-100 h-100 bg-light' style={{"marginTop": "3.5em"}}>
                <div className={'d-flex align-items-center justify-content-center h-100'}>
                    <p className='text-center'>please select any company hades<br/>
                        you are able to view all details of company</p>
                </div>
            </Card>}
        </>
    )
}

export default CompanyDetails