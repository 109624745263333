import {call, put, takeEvery, takeLatest} from 'redux-saga/effects';
import {post} from '../actions/api';
import {LOGIN_SUCCESS, LOGIN_REQUEST, LOGIN_FAILED, LOGOUT_REQUEST,LOGOUT,AUTH_DATA} from '../types';

function* auth({type, payload,navigate}) {
    try {
        const data = yield  call(post, "token/", payload);
        if (data) {
            console.log("saga -auth", data)
            //axios.defaults.headers.common['Authorization'] = "Token " + data.token
            yield put({"type": LOGIN_SUCCESS, "data": data});
            navigate("/dashboard");
        }
    } catch (error) {
        console.log(error)
        yield put({"type": LOGIN_FAILED, "error": {"message": "Invalid Credentials"}});
        navigate("/"); 
    }
}

function* logout({type, navigate}) {
    try {
        console.log("saga logout");
        localStorage.removeItem("user");
        yield put({"type": LOGOUT});
        navigate("/");
    } catch (error) {
        console.log(error)
        yield put({"type": LOGIN_FAILED, "error": {"message": "Invalid Credentials"}});
    }
}
function* authData({type, navigate}) {
    try {
        yield put({"type": AUTH_DATA});
    } catch (error) {
        console.log(error)
    }
}

function* authSaga() {
    yield takeLatest(LOGIN_REQUEST, auth);
    yield takeEvery(LOGOUT_REQUEST, logout);
}

export default authSaga