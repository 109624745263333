import {all} from 'redux-saga/effects';
import userSaga from "./users";
import authSaga from "./login";
import dashBoardSaga from './dashboard';
import chartSaga from './charts';
import cnoteSaga from './cnote';
import cnorSaga from './consignor'
import placeSaga from './place'
import companySaga from './company';
import transportSaga from './transport';
import invoiceSaga from './invoice';
import groupSaga from './group';
export default  function* rootSaga() {
    yield all([
        userSaga(),
        authSaga(),
        dashBoardSaga(),
        chartSaga(),
        cnoteSaga(),
        cnorSaga(),
        placeSaga(),
        companySaga(),
        transportSaga(),
        invoiceSaga(),
        groupSaga()
    ]);
}
