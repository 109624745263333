import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import List from "../../ui/list/List";
import { listData } from "../../assets/DummyDatas/data";
import { useDispatch, useSelector } from "react-redux";
function AddList({listData}) {
    return (
        <div className={"overflow-auto"}>
            {listData && listData.map((data, i) => {
                return <List key={i} data={data} className={"mb-2 btn"}  />;
            })}
        </div>
    );
}

export default AddList;
