import * as type from '../types';
const storeName = "place"
const initialState = {
    data: JSON.parse(localStorage.getItem(storeName)) || [],
    loading: false,
    message: "",
    success: false,
};

export default function place(state = initialState, action) {
    // console.log("action",action.type)
    switch (action.type) {
        case type.GET_PLACE_REQUEST || type.POST_PLACE_REQUEST || type.PUT_PLACE_REQUEST || type.PATCH_PLACE_REQUEST ||
            type.DELETE_PLACE_REQUEST:
            console.log("data" + action.payload);
            return {
                ...state,
                loading: true,
            };
        case type.PLACE_SUCCESS:
            let datas = [...state.data]
            if (typeof action.data === 'object') {
                if (Array.isArray(action.data)) {
                    datas = action.data
                }else{
                    datas.push(action.data)
                }
            }
            if (typeof action.data !== 'object') {
                datas = datas.filter(d => d.id !== action.data);
            }
            localStorage.setItem(storeName, JSON.stringify(datas))
            return {
                ...state,
                data: action.data,
                loading: false,
                success: true,
                message: "Data Successfully Saved"

            };
        case type.PLACE_FAILED:
            return {
                ...state,
                loading: false,
                message: "Failed This Action ",
                success: false
            };
        default:
            return state;
    }

}