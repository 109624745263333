import React, { useState } from 'react';
import "./main.css";
import { Container, Row, Col, Card, Modal, ListGroup } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
// import CompanyDetails from './CompanyDetails';
import GroupForm from './GroupForm';
import { useDispatch, useSelector } from 'react-redux';
import { GET_GROUP_REQUEST, SEARCH_GROUP_REQUEST, DELETE_GROUP_REQUEST } from '../../redux/types';
import List from '../list/List';
import Loader from '../../components/loader/Loader';

function Group() {

    let [more, setMore] = useState({});
    const [show, setShow] = useState(false);
    let dispatch = useDispatch();
    let groupState = useSelector(state => state.group)
    const [rows, setRows] = React.useState(groupState.data)
    let [data, setData] = useState({});
    React.useEffect(() => {
        dispatch({ type: GET_GROUP_REQUEST, payload: "group/0/" });

        setRows([...groupState.data])

        return function cleanup() {
            console.log("cleaned up")
        }
    }, [])
    React.useEffect(() => {
        setRows([...groupState.data])
        return function cleanup() {
            console.log("cleaned up")
        }
    }, [groupState])




    const onListClick = (action, data) => {
        if (action === "update") {
            console.log("updating data:", data);
            setData({ ...data });
            setShow(true);
        } if (action === "delete") {
            console.log("deleteing data:", data);
            dispatch({type:DELETE_GROUP_REQUEST,payload:data.id})


        }
        if (action === "more") {
            setMore(data);
        }
    }
    const onSearch = (e) => {
        setTimeout(() => {
            dispatch({
                type: SEARCH_GROUP_REQUEST, payload: "group/0/?search=" + e.target.value, callback: (list) => {
                    console.log("list", list)
                    setRows([...list]);
                }
            })
        }, 400)
    }
    return (
        <>
            <Loader loading={groupState.loading} />
            <Container fluid>
                <Modal backdrop="static" centered show={show} size="sm" onHide={() => {
                    setShow(false); setData({})
                }}>
                    <Modal.Header closeButton backdrop={"false"}>
                        <Modal.Title id="contained-modal-title-vcenter">

                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <GroupForm data={data} />
                    </Modal.Body>
                </Modal>
                <Row>
                    {/* <Col md={12}>
                    <ListGroup horizontal className='title-bar'>
                        <ListGroup.Item><Icon.Envelope /> New Mail</ListGroup.Item>
                        <ListGroup.Item disabled><Icon.Trash3 /> Delete</ListGroup.Item>
                        <ListGroup.Item disabled> Archive</ListGroup.Item>
                        <ListGroup.Item disabled>Report</ListGroup.Item>
                        <ListGroup.Item disabled>Sweep</ListGroup.Item>
                        <ListGroup.Item disabled>Move to</ListGroup.Item>
                        <ListGroup.Item disabled>Reply all</ListGroup.Item>
                        <ListGroup.Item disabled className='w-100'> </ListGroup.Item>
                    </ListGroup>
                </Col>
            </Row>
            <Row> */}
                    <Col md={3} className={"g-0"}>
                        <Container fluid>
                            <Row>
                                <Col><p className={"my-4 mb-2"}>Consignor List</p></Col>
                            </Row>
                            <Row>
                                <Col><Card className={"p-1"}>
                                    <ListGroup horizontal>
                                        <ListGroup.Item className={"w-100"}>
                                            <input onChange={onSearch} type={"text"} placeholder={"search here..."}
                                                className={"search-box form-control form-control-sm"} />
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <Icon.Plus style={{ fontSize: 20, cursor: "pointer" }} title={"Create New Company"}
                                                onClick={() => {
                                                    setShow(true);
                                                    //    setMore(null);
                                                }}
                                            />
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='company-list'>
                                    <div className={"overflow-auto"}>

                                        {rows && rows.map((data, i) => {
                                            //should not remove img key
                                            return <List actions={true} onClick={onListClick} key={i} data={data} keys={["img", "name", "created_at", "phone"]} className={"mb-2 btn"} />;
                                        })}
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <Col md={7} className={"g-0"}>
                        <Container className={"h-90"}>
                            {/* <CompanyDetails data={more} /> */}
                        </Container>
                    </Col>
                    <Col md={2} className={"g-0"}>
                        <Container className={"h-90 pt-5"}>
                            <p className={"mt-4"}>Create company with user.</p>
                            <p>Company user have all permission under the company base.</p>
                            <p>Project creates under each company but project should be linked with user</p>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default Group