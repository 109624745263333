import React, { useRef } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Dropdown, Button, Row, Col, Table, Container } from 'react-bootstrap';
import Image from "react-bootstrap/Image";
import * as Icon from 'react-bootstrap-icons';
import readXlsxFile from 'read-excel-file'
import writeXlsxFile from 'write-excel-file'
import SelectBox from '../../components/float-inputs/SelectBox'
import { get_templates, post_message, send_message } from "./services";

const HEADER_ROW = [
  {
    value: 'Name',
    fontWeight: 'bold'
  },
  {
    value: 'Date of Birth',
    fontWeight: 'bold'
  },
  {
    value: 'Cost',
    fontWeight: 'bold'
  },
  {
    value: 'Paid',
    fontWeight: 'bold'
  }
]

const DATA_ROW_1 = [
  // "Name"
  {
    type: String,
    value: 'John Smith'
  },

  // "Date of Birth"
  {
    type: Date,
    value: new Date(),
    format: 'mm/dd/yyyy'
  },

  // "Cost"
  {
    type: Number,
    value: 1800
  },

  // "Paid"
  {
    type: Boolean,
    value: true
  }
]

const data = [
  HEADER_ROW,
  DATA_ROW_1,
]

function Message() {
  let templateRef = useRef()
  let messageRef = useRef()
  const renderAfterCalled = React.useRef(-1);
  let [contacts, setContacts] = React.useState([])
  let [templates, setTemplates] = React.useState([])
  let [selTemplate, setSeleTemplate] = React.useState({})
  let [selTxt, setSelTxt] = React.useState('')
  let [params, setParams] = React.useState({})
  let [status, setStatus] = React.useState({})
  let [counts, setCounts] = React.useState(0)
  let [message, setMessage] = React.useState("")
  let [head, setHead] = React.useState([])

  const init = () => {
    if (renderAfterCalled.current !== contacts.length) {
      get_templates((res) => {
        console.log("templates", res.data)
        setTemplates(res.data)
      })
    }
    setContacts([...contacts])
    renderAfterCalled.current = contacts.length
  }

  React.useEffect(() => {
    init()
  }, [])

  React.useEffect(() => {
    init()
  }, [message])

  const readExcelFile = (e) => {
    setCounts(0)
    readXlsxFile(e.target.files[0]).then((rows) => {
      let h = rows.shift()
      setHead(h)
      if (h[0].toLowerCase() === "contact") {
        setContacts([...rows])
      } else {
        alert("First column should be a contact")
        return 0
      }
    })
  }
  function delay(t, data) {
    return new Promise(resolve => {
      setTimeout(resolve, t, data);
    });
  }

  const selectTemplate = (value, label) => {
    let customTemp = {
      "recipient_type": "individual",
      "messaging_product": "whatsapp",
      "to": "9656291915",
      "type": "template", "template": {
        "language": {
          "code": "ml",
          "policy": "deterministic"
        },
      }
    }
    let textList = {}
    for (var i = 0; i < templates.length; i++) {
      if (templates[i].name === value) {
        let temp = templates[i];
        console.log("templage:", temp)
        customTemp.template["name"] = temp["name"]
        customTemp.template["language"]["code"] = temp["language"]
        let body = undefined
        let header = undefined
        for (var j = 0; j < temp.components.length; j++) {
          if (temp.components[j].example) {
            let body_text = temp.components[j].example.body_text
            let header_handle = temp.components[j].example.header_handle
            let format = temp.components[j].format
            format = String(format ? format : "text").toLowerCase()
            let type = String(temp.components[j].type).toLowerCase()
            textList[type] = temp.components[j].text
            if (body_text) {
              body = {
                type: type,
                parameters: body_text[0].map((d) => {
                  let tmp = {}
                  tmp["type"] = format
                  tmp[format] = d
                  return tmp
                })
              }
            } else if (header_handle) {
              header = {
                type: type,
                parameters: header_handle.map((d) => {
                  let tmp = {}
                  tmp["type"] = format
                  tmp[format] = { link: "https://vikasmoney.com/assets/images/resources/logo-1.png" }
                  return tmp
                })
              }
            }
          }
        }

        customTemp.template["components"] = [header, body].filter(n => n)
        console.log("Custom Temp:", JSON.stringify(customTemp))
        setSeleTemplate(customTemp)
        break;
      }
    }
    setSelTxt(textList)
    console.log("TEXTS", textList)
    // testing_message(customTemp)
  }
  const exportExcel = async () => {
    let exportHeader = []
    exportHeader.push({ value: "SL NO", fontWeight: 'bold' })
    head.map((value) => {
      exportHeader.push({ value: value, fontWeight: 'bold' })
    })
    exportHeader.push({ value: "Status", fontWeight: 'bold' })
    let hasRow = false
    let rows = [exportHeader]
    contacts.map((contact, i) => {
      hasRow = true
      let cols = []
      cols.push({ column: 'SL NO.', type: String, value: String(i + 1) })
      contact.map((c, j) => {
        cols.push({ column: head[j], type: String, value: String(c) })
      })
      cols.push({ column: 'Status', type: String, value: status[contact[0]] ? "Success" : "Pending" })
      rows.push(cols)

    })
    console.log(rows)
    let dt = new Date().toLocaleString().replace(',', '')
    await writeXlsxFile(rows, {
      //columns, // (optional) column widths, etc.
      fileName: `message_report_${dt}.xlsx`
    })
  }
  const onVariableChange = (index, v, l) => {
    params[String(index)] = parseInt(v)
    setParams({ ...params })
    return contacts[0][parseInt(v)]
  }
  const createPreview = (text, params) => {
    if (text === undefined) return
    for (let i = 0; i <= params.length; i++) {
      if (params[i]) {
        text = text.replace("{{" + (i + 1) + "}}", "{{" + params[i].text + "}}")
      }
    }
    return text
  }
  const createPayload = (contact) => {
    let payload = { ...selTemplate }
    payload.to = contact[0].replace("+", "")
    let template = { ...payload.template }
    let components = [...template.components]
    components = components.map((component, k) => {
      if (component && component.type === 'body') {
        component.parameters.map((param, i) => {
          param.text = contact[params[i]]
          return param
        })
        return component
      }
      return component
    })
    template.components = components
    payload["template"] = template
    return payload
  }
  let d = {};
  const sendSingleMessage = (contact) => {
    d[contact[0]] = undefined
    let payload = createPayload(contact)
    send_message(payload, (res) => {
      d[contact[0]] = res;
      if (res) setCounts(prev => prev + 1)
      setStatus({ ...d })
    })
    setMessage(contact[0])
  }
  const sendMessage = () => {

    console.log(templateRef.current.value)
    if (templateRef.current.value === undefined || templateRef.current.value.length === 0) {
      alert("Please select template")
      return
    }

    contacts.map(async (contact, i) => {
      if (contact) {
        setTimeout(() => {
          sendSingleMessage(contact)
        }, i * 2000);
      }
    })
    setContacts([...contacts])

  }


  return (
    <>
      <Container>
        <Row>
          <Col md={3} xs={3}>
            <input type="file" id="input" onChange={readExcelFile} className="form-control form-control-md" />

          </Col>
          <Col md={3} xs={3}>
            <SelectBox ref={templateRef} options={templates} keys={{ "label": "name", value: "name" }} onChange={selectTemplate} />
          </Col>
          <Col md={2} xs={2}>
            <Button size="md" onClick={sendMessage}>Start Message</Button>
          </Col>
          <Col md={1} xs={1}>
            <Button size="md" onClick={exportExcel}>Export</Button>

          </Col>

          <Col md={3}>
            <div className="alert alert-primary p-1" role="alert">
              Sending message to : {message}
            </div>
          </Col>
        </Row>
        <Row>
          {selTemplate.template && selTemplate.template.components.map((component, i) => {
            if (component) {
              return <Col md={3} key={i}>
                <h4>{component.type}</h4>
                {
                  component.parameters.map((param, j) => {
                    if (param.type === 'image') {
                      return <input className={"form-control form-control-md"} key={j} value={param[param.type].link}
                        onChange={(e) => {
                          param[param.type].link = e.target.value;
                          setSeleTemplate({ ...selTemplate })
                        }} />
                    }
                    return (
                      <div className={"mt-1"}>
                        <SelectBox key={j} onChange={(v, l) => {
                          param.text = onVariableChange(j, v, l);
                          setSeleTemplate({ ...selTemplate })
                        }}
                          options={head.map((h, i) => { return { label: h, value: i } })}
                          keys={{ "label": "label", "value": "value" }}
                        />
                      </div>
                    )
                  })
                }
                <div className={"bg-light mt-1 p-2"}>
                  {createPreview(selTxt[component.type], component.parameters)}
                </div>
              </Col>
            }
          })}
          <Col md={(12 - (selTemplate.template && selTemplate.template.components.length * 3))}>
            {contacts.length !== 0 && <div className={"mt-5"} style={{
              width: 100, height: 100, borderRadius: "100%", position: 'relative',
              border: "5px solid gray", textAlign: "center", verticalAlign: "center"
            }}><span style={{
              position: 'absolute', margin: 'auto', fontSize: 30,
              top: 0, bottom: 0, left: 0, right: 0,
              display: "inline-block", height: 50
            }}>{counts}/{contacts.length}</span></div>}

          </Col>

        </Row>
        <Row className="mt-3">
          <Col md={9}>

            <div style={{ height: "80vh", overflowY: "auto" }}>
              <Table striped={"true"} bordered={"true"} hover={"true"} size="sm">
                <thead>

                  <tr>
                    <th width={20}><label style={{ width: 40 }}>SL No</label></th>
                    {head.map((h, i) => {
                      return <th key={i + "th"}>{h}</th>
                    })

                    }
                    <th width={20}>Status</th>
                    <th width={20}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {contacts.map((row, i) => {
                    console.log(status[row[1]] === undefined);
                    return (
                      <tr key={i}>
                        <th>{i + 1}</th>
                        {row.map((r, i) => {
                          return <td key={i + "td"}>{r}</td>
                        })}
                        <td>{status[row[0]] === undefined ? "Pending" : (status[row[0]] ? "Success" : "Failed")}</td>
                        <td><Icon.Whatsapp className={status[row[0]] === undefined ? "text-info" : status[row[0]] ? "text-success" : "text-danger"} onClick={() => { sendSingleMessage(row) }} /></td>
                      </tr>
                    )
                  })}

                </tbody>
              </Table>
              {contacts.length === 0 && <h6 className="text-center mt-5 text-info">No record found</h6>}
            </div>

          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Message;