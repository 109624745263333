import React from 'react'
import XForm from '../../components/float-inputs/XForm'
import TextBox from '../../components/float-inputs/TextBox'
import PasswordBox from '../../components/float-inputs/PasswordBox'
import {Link, useNavigate} from "react-router-dom";
import {useSelector, useDispatch, connect} from 'react-redux';
import {LOGIN_REQUEST} from '../../redux/types';
import { Card ,Button, Spinner,Form} from 'react-bootstrap';

function LoginForm() {
  let dispatch = useDispatch()
  let navigate = useNavigate()
  let auth = useSelector(state=>state.auth)
  return (
    <div className="d-flex align-items-center" style={{height:"100vh"}}>
      <div className="d-flex justify-content-center w-100">
        <Card className="p-2 w-25 h-25">
          
      
        <XForm className=''callback={(e,data)=>{
            dispatch({
              type:LOGIN_REQUEST,
              payload:data,
              navigate:navigate
            })
        }}>
            <TextBox className={" mb-1 blank-5"}name={"username"}
            label={"Username"}size={"lg"}/>
            <PasswordBox name={"password"}
            label={"Password"}size={"lg"}/>
            <Form.Group className="d-flex align-items-center">
            <Button className={'mt-3 px-4'}as="input" type="submit" value="Submit" />
            <span className="pt-3 px-2">
            {auth.loading && <Spinner/>} 
            </span>
            </Form.Group>
        </XForm>
        </Card>   
        </div>     
    </div>
  )
}

export default LoginForm