import React, {useState} from 'react';
import Form from "react-bootstrap/Form";

function PasswordBox(props) {
    const [error, setError] = useState("")
    const [passwordShown, setPasswordShown] = useState(false);
    const size = props.size || "sm"

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    }
    // const validate = (e) => {
    //     let input = e.target.value;
    //
    //     if (!validator.isStrongPassword(input, {
    //         minLength: 8,
    //         minLowercase: 1,
    //         minUppercase: 1,
    //         minNumbers: 1,
    //         minSymbols: 1,
    //         maxLength: 16
    //     })) {
    //         e.target.setCustomValidity("Weak Password");
    //         setError("Weak Password")
    //
    //     } else {
    //         e.target.setCustomValidity("");
    //         setError("Strong Password")
    //     }
    // }

    function validate(e) {
        var p = e.target.value;
        var errors = [];
        if (p.length < 8) {
            errors.push("Your password must be at least 8 characters");
        }
        if (p.search(/[a-z]/i) < 0) {
            errors.push("Your password must contain at least one letter.");
        }
        if (p.search(/[0-9]/) < 0) {
            errors.push("Your password must contain at least one digit.");
        }
        if (errors.length > 0) {
            var er = errors.join("\n");
            e.target.setCustomValidity(er);
            setError(er);
            return false;
        }
        e.target.setCustomValidity("");
        setError("Strong Password")
        return true;
    }

    return (

        <Form.Group className={"mt-2"}>
            <div className={"float-input"}>
                <Form.Control
                    required
                    type="password"
                    i="far fa-eye" onClick={togglePassword}
                    {...props}
                    placeholder={" "}
                    onInput={(e) => {
                        validate(e);
                    }}
                />
                <label htmlFor={props.name}>{props.label}</label>
                {error && <Form.Control.Feedback type="invalid"> {error} </Form.Control.Feedback>}
            </div>

        </Form.Group>
    )
}

export default PasswordBox;
            