import { call, put, takeEvery } from 'redux-saga/effects';
import { get, post, patch, del } from '../actions/api';
import * as action from '../types';
import { isNumber } from '../../components/date/dates';
function* _get({ type, payload }) {
    let url = payload;
    if (isNumber(payload)) {
        payload = payload ? payload : 0;
       url =  `company/${payload}/`
    }
    console.log("request called",url)
    try {
        const data = yield call(get, url);
        if (data.error) {
            yield put({ "type": action.COMPANY_FAILED, "data": data });
        } else if (data) {
            console.log("fecth total data - ", data.length)
            yield put({ "type": action.COMPANY_SUCCESS, "data": data });
            yield put({ "type": action.COMPANY_FAILED, "data": { error: false, message: "" } });
        } else {
            yield put({ "type": action.COMPANY_FAILED, "data": { error: true, message: "Unknown Error" } });
        }
    } catch (error) {
        yield put({ "type": action.COMPANY_FAILED, "data": { error: true, message: "Unknown Error" } });
    }

}

function* _post({ type, payload, callback }) {
    try {
        console.log("saveNote", payload)
        const data = yield call(post, "company/", payload);
        if (data.error) {
            console.log("error1:", JSON.stringify(data));
            yield put({ "type": action.COMPANY_FAILED, "data": data });
        } else if (data) {
            console.log("success:", JSON.stringify(data));
            console.log("fecth total data - ", data.length)
            yield put({ "type": action.COMPANY_SUCCESS, "data": data });
            yield put({ "type": action.COMPANY_FAILED, "data": { error: false, message: "" } });
        } else {
            console.log("error2:", JSON.stringify(data));
            yield put({ "type": action.COMPANY_FAILED, "data": { error: true, message: "Unknown Error" } });
        }
        if (callback) callback(data)
    } catch (error) {
        console.log("error3:");
        yield put({ "type": action.COMPANY_FAILED, "data": { error: true, message: "Unknown Error" } });
        if (callback) callback({ error: true, message: "Unknown Error" })
    }

}
function* _put({ type, payload, callback }) {
    try {
        console.log("editNote", payload)
        const data = yield call(put, `company/${payload.id}/`, payload);
        if (data.error) {
            yield put({ "type": action.COMPANY_FAILED, "data": data });
        } else if (data) {
            console.log("fecth total data - ", data.length)
            yield put({ "type": action.COMPANY_SUCCESS, "data": data });
            yield put({ "type": action.COMPANY_FAILED, "data": { error: false, message: "" } });
        } else {
            yield put({ "type": action.COMPANY_FAILED, "data": { error: true, message: "Unknown Error" } });
        }
        if (callback) callback({ error: true, message: "Unknown Error" })
    } catch (error) {
        yield put({ "type": action.COMPANY_FAILED, "data": { error: true, message: "Unknown Error" } });
        if (callback) callback({ error: true, message: "Unknown Error" })
    }
}
function* _patch({ type, payload, callback }) {
    try {
        console.log("editNote", payload)
        const data = yield call(patch, `company/${payload.id}/`, payload);
        if (data.error) {
            yield put({ "type": action.COMPANY_FAILED, "data": data });
        } else if (data) {
            console.log("fecth total data - ", data.length)
            yield put({ "type": action.COMPANY_SUCCESS, "data": data });
            yield put({ "type": action.COMPANY_FAILED, "data": { error: false, message: "" } });
        } else {
            yield put({ "type": action.COMPANY_FAILED, "data": { error: true, message: "Unknown Error" } });
        }
        if (callback) callback(data)
    } catch (error) {
        yield put({ "type": action.COMPANY_FAILED, "data": { error: true, message: "Unknown Error" } });
        if (callback) callback({ error: true, message: "Unknown Error" })
    }

}
function* _del({ type, id, callback }) {
    try {
        console.log("delete", id)
        const data = yield call(del, "company/", id);
        if (data.error) {
            yield put({ "type": action.COMPANY_FAILED, "data": data });
        } else if (data) {
            console.log("fecth total data - ", data.length)
            yield put({ "type": action.COMPANY_SUCCESS, "data": data });
            yield put({ "type": action.COMPANY_FAILED, "data": { error: false, message: "" } });
        } else {
            yield put({ "type": action.COMPANY_FAILED, "data": { error: true, message: "Unknown Error" } });
        }
        if (callback) callback(data)
    } catch (error) {
        yield put({ "type": action.COMPANY_FAILED, "data": { error: true, message: "Unknown Error" } });
        if (callback) callback({ error: true, message: "Unknown Error" })
    }

}
function* companySaga() {
    yield takeEvery(action.GET_COMPANY_REQUEST, _get);
    yield takeEvery(action.POST_COMPANY_REQUEST, _post);
    yield takeEvery(action.PUT_COMPANY_REQUEST, _put);
    yield takeEvery(action.PATCH_COMPANY_REQUEST, _patch);
    yield takeEvery(action.DELETE_COMPANY_REQUEST, _del);
}

export default companySaga