import SideMenu, { menuItems } from "../SideMenu";
import { v4 as uuidv4 } from 'uuid';
import { Routes, Route } from "react-router-dom";
import Header from "../Header";
import LoginForm from "../../ui/login/LoginForm";
import './layout.css';

function Error() {
    return (<h1>You have to create new page</h1>)
}
function Layout() {
    let subMenus = []
    return (
        <>
            <div className={`page-wrapper`}>
                <Header />
                <div className="d-flex body-wrapper">
                    <SideMenu />
                    <div className={`p-2 content`}>
                        <Routes>
                            <Route exact={"true"} path={"/"} element={<LoginForm />}></Route>
                            {
                                menuItems.map((menu, i) => {
                                    if (menu.subMenus) {
                                        subMenus = subMenus.concat(menu.subMenus)
                                        console.log("sub menu-:", subMenus)
                                    }
                                    return <Route key={i} exact={"true"} path={menu.to} element={menu.component}></Route>
                                })
                            }
                            {
                                subMenus.map((menu, i) => {
                                    return <Route key={i} exact={"true"} path={menu.to} element={menu.component}></Route>
                                })
                            }
                            <Route path={"*"} element={<Error />}></Route>
                        </Routes>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Layout;
