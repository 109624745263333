import React, {useEffect, useState} from 'react'
import {} from "chart.js/auto";
import {Bar} from "react-chartjs-2";
import {Card} from 'react-bootstrap';
import {get} from '../../redux/actions/api.js';
import {useSelector, useDispatch, connect} from 'react-redux';
import {GET_CHART_REQUEST} from '../../redux/types';

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: "top",
        },
        title: {
            display: true,
            text: "Bar Chart",
        },
    },
};

const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"];
let _data = {
    labels,
    datasets: [
    ],
};

function BarChart() {
    let dispatch = useDispatch()
    const datas = useSelector(state => state.chart.datas);
    let [dataSet, setDataSet] = useState(_data)
    useEffect(() => {
        // dispatch({
        //     type: GET_CHART_REQUEST,
        //     payload: {}
        // });
        // if (datas) {
        //     datas && datas?.data?.map((data) => {
        //         labels.push(new Date(data.date_joined));
        //     })
        // }
        //
        _data.datasets.push({
            label: "hello",
            data: [1000, 500, 250, 100, 50, 25, 1],
            backgroundColor: " rgb(7, 7, 207)",
        });

        // setDataSet(_data);

    }, [''])
    const adddata = ()=>{
        _data.datasets[0]={
            label: "User",
            data: [234, 678, 110, 560, 340, 670, 111],
            backgroundColor: " rgb(7, 7, 207)",
        };
        setDataSet(_data);
    }

    return (
        <Card className='p-1' onClick={()=>{adddata()}}>
            {_data && <Bar options={options} data={dataSet} height={80}/>}
        </Card>
    )
}

export default BarChart