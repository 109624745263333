import * as type from '../types';
const storeName = "users"
const initialState = {
    data: JSON.parse(localStorage.getItem(storeName)) || [],
    loading: false,
    message:""
};

export default function users(state = initialState, action) {
    // console.log("action",action.type)
    switch (action.type) {
        case type.GET_USERS_REQUEST:
            
            return {
                data: action.data,
                ...state,
                loading: true,
            };
        case type.SUCCESS_USERS:
            let datas = [...state.data]
            if (typeof action.data === 'object') {
                if (Array.isArray(action.data)) {
                    datas = action.data
                }else{
                    datas.push(action.data)
                }
            }
            if (typeof action.data !== 'object') {
                datas = datas.filter(d => d.id !== action.data);
            }
            localStorage.setItem(storeName, JSON.stringify(datas))
            return {
                ...state,
                data: action.data,
                loading: false
            };
        case type.FAILED_USERS:
            return {
                ...state,
                loading: false,
                message:"Failed This Action "
            };
        case type.POST_USER_REQUEST:
            console.log("user" + action.payload);
            return {
                ...state,
                loading: true,
            };
        case type.POST_SUCCESS:
            console.log("user" + action.payload);
            return {
                ...state,
                
                loading: false,
                message:"Data Successfully Saved"
            };
        default:
            return state;
    }

}