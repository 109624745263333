import React, { useEffect } from 'react';
import DetailsCard from '../../components/cards/DetailsCard';
import BarChart from '../../components/charts/BarChart';
import LineChart from '../../components/charts/LineChart';
import PieChart from '../../components/charts/PieChart';
import "./dashboard.css";
import { Container, Row, Col } from 'react-bootstrap';
import AddList from '../../components/addList/AddList';
import { useSelector, useDispatch } from 'react-redux';
import { GET_DASHBOARD_REQUEST } from '../../redux/types';

function Dashboard(props) {
    const renderAfterCalled = React.useRef(false);
    let dispatch = useDispatch()
    const dashboardState = useSelector(state => state.dashboard);
    useEffect(() => {
        if (dashboardState.data.length !== renderAfterCalled.current)
            dispatch({
                type: GET_DASHBOARD_REQUEST,
                payload: {}
            });
        console.log("DASHBOARD:", dashboardState.data);
        renderAfterCalled.current = dashboardState.data.length
        return function cleanedUp() {
            console.log("cleaning up");
        }

    }, [dispatch, dashboardState])
    return (

        <Container fluid>
            <Row>
                <Col md={9} className={"g-0"}>
                    {dashboardState.data && <Container fluid>
                        <Row>
                            <Col md={12}>
                                <p className={"my-4 mb-2"} >General Report</p>
                            </Col>
                        </Row>
                    </Container>}
                    <DetailsCard data={dashboardState.data.data} />
                    <Container fluid>
                        <Row>
                            <Col md={12}>
                                <p className={"my-4 mb-2"}>General Chart</p>
                            </Col>
                        </Row>
                    </Container>
                    <Container fluid>
                        <Row>

                            <Col md={6}>
                                <BarChart />
                            </Col>
                            <Col md={3}>
                                <LineChart />
                            </Col>
                            <Col md={3}>
                                <PieChart />

                            </Col>
                        </Row>
                    </Container>
                </Col>

                <Col md={3} className={"g-0"}>
                    <Container fluid>
                        <Row>
                            <Col><p className={"my-4 mb-2"}>Users</p></Col>
                        </Row>
                        <Row>
                            <Col className='users-list'>
                                <AddList />
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    )

}


export default Dashboard