import React from 'react';
import "./App.css";
import Layout from "./components/layouts/Layout";
import LoginForm from "./ui/login/LoginForm";
import { useNavigate } from "react-router-dom";
import {connect } from 'react-redux';
import axios from "axios";

function App(props) {
    let navigate = useNavigate()
    let user = JSON.parse(localStorage.getItem("auth"))
    React.useEffect(() => {

        if (user) {
            console.log("App Token", user.token)
            axios.defaults.headers.common['Authorization'] = "Token " + user.token
        }
        console.log("path name",window.location.pathname)
        if (user && window.location.pathname === '/') {

            console.log("path name:", window.location.pathname);
            navigate("/dashboard");
        }
    })
    return (
        <>
            {
                !user && <LoginForm />
            }
            {
                user && <Layout />
            }

        </>

    )



}



export default connect()(App);
