import Form from "react-bootstrap/Form";
import React from "react";
const SelectBox = (props, ref) => {
    let [search, setSearch] = React.useState('')
    let options = props.options || []
    let keys = props.keys || { label: "label", value: "value" }
    let multiple = props.multiple || false
    let searchable = props.searchable
    let _size = props.size
    let label = props.label || ""
    let name = props.name || ""
    let [v,setV] = React.useState(props.defaultValue)
    let onselect = props.onChange || function (value, label) {
    }
    let onchange = props.onChange || function (value, label) {
    }
    const onChangeValue = (selector) => {
        let text = selector.options[selector.selectedIndex].text
        setV(selector.value)
        onchange(selector.value, text)

    }
    const onSearch = (e) => {
        setSearch(e.target.value);
    }
    const onKeyDown = (e) => {
        console.log("Keycode:", e.keyCode)
        switch (e.keyCode) {
            case 40:
                e.target.nextElementSibling.focus();
                break;
        }
    }
    return (

        <div className={'float-input'}>
            <input className={"form-control form-control-sm"} placeholder={""} onChange={(e) => { onSearch(e) }} onKeyDown={onKeyDown} type={(searchable ? "text" : "hidden")} />
            <Form.Select value={v} className={"form-control form-control-sm"} id={name} size={_size} name={name} ref={ref} multiple={multiple} onChange={(e) => { onChangeValue(e.target) }}>
                {!searchable && <option key={0} value=""> Select {label} </option>}
                {
                    options.map((option, i) => {
                        if (search.length > 0 && option[keys.label].toLowerCase().includes(search.toLowerCase())) {
                            return <option key={i} value={option[keys.value]} > {option[keys.label]}</option>
                        } else if (search.length == 0) {
                            return <option key={i} value={option[keys.value]}> {option[keys.label]} </option>
                        }
                    })
                }
            </Form.Select>
            {searchable && <Form.Label htmlFor={name}>{label}</Form.Label>}
        </div>

    )
}
export default React.forwardRef(SelectBox);